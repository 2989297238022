<template>
  <v-container fluid>
    <PageTitle title="Cashout" />
    <v-card class="mb-2">
      <v-card-text>
        <v-row class="d-flex align-end">
          <v-col class="flex-grow-1">
            <v-card-text class="px-0 mb-2 py-0">Filter Data</v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filter.date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      dense
                      solo
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      class="softblue"
                      clear-icon="mdi-close-circle"
                      @click:clear="clearDate"
                      clearable
                    />
                  </template>
                  <v-date-picker v-model="filter.date" color="zipay" range @input="searchDate" />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-select
                  hide-details="auto"
                  :items="trxStatus"
                  item-text="label"
                  item-value="value"
                  dense
                  label="Status"
                  v-model="filter.trxStatus"
                  solo
                  class="softblue"
                  @change="searchTrxStatus"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  v-model="filter.searchTerm"
                  placeholder="Type here..."
                  dense
                  solo
                  hide-details="auto"
                  class="softblue"
                  @input="searchName"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="flex-grow-0">
            <v-btn depressed color="success" @click="exportExcel">Export</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Cashout</v-card-title>
      <v-card-text>
        <v-data-table 
          :headers="headers" 
          :items="cashouts"
          :hide-default-footer="true"
          :items-per-page="Number(query.limit)"
        >
          <template v-slot:[`item.referenceId`]="{ item }">
            <router-link :to="`/cashout/${item.referenceId}`">{{ item.referenceId }}</router-link>
          </template>
          <template v-slot:[`item.adminFee`]="{ item }">
            {{ rupiahFormat(item.adminFee) }}
          </template>
          <template v-slot:[`item.deductedAmount`]="{ item }">
            {{ rupiahFormat(item.deductedAmount) }}
          </template>
          <template v-slot:[`item.transferedAmount`]="{ item }">
            {{ rupiahFormat(item.transferedAmount) }}
          </template>
        </v-data-table>
        <v-row class="d-flex justify-end align-baseline">
          <v-col cols="2">
            <p>Rows per page</p>
          </v-col>
          <v-col cols="1">
            <v-text-field 
              v-model="query.limit" 
              single-line
              outlined
              dense
            />
          </v-col>
          <v-col cols="1">
            <p>{{ query.page }} of {{ meta.total_page }}</p>
          </v-col>
          <v-col cols="1">
            <v-pagination 
              :elevation="0"
              v-model="query.page" 
              :length="meta.total_page" 
              :total-visible="0" 
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import currency from "@/libs/currency";
import PageTitle from "@/components/molecules/PageTitle.vue";
import { mapActions, mapGetters } from "vuex";
import { excelParser } from "@/libs/export";

export default {
  mixins: [currency],
  components: { 
    PageTitle 
  },
  data() {
    return {
      headers: [
        { text: "Date & Time", value: "createdAt" },
        { text: "No. Telepon", value: "msisdn" },
        { text: "Reference ID", value: "referenceId" },
        { text: "Bank Account", value: "BankMaster.name" },
        { text: "No. Rekening", value: "bankAccountNo" },
        { text: "Deducted Amount", value: "deductedAmount" },
        { text: "Admin Fee", value: "adminFee" },
        { text: "Transfered Amount", value: "transferedAmount" },
        { text: "Status", value: "trxStatus" },
      ],
      filter: {
        date: ['', ''],
        trxStatus: '',
        searchTerm: ''
      },
      query: {
        limit: 10,
        page: 1
      },
      trxStatus: [
        {value: '', label: 'Semua'},
        {value: 'pending', label: 'Pending'},
        {value: 'success', label: 'Success'},
        {value: 'failed', label: 'Failed'},
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 100,
      }
    }
  },
  computed: {
    ...mapGetters('cashoutModule', ['cashouts', 'meta']),
  },
  methods: {
    ...mapActions('cashoutModule', ['getCashout']),
    clearDate() {
      const filter = {
        startDate: '',
        endDate: '',
        trxStatus: this.filter.trxStatus,
        searchTerm: this.filter.searchTerm,
      }

      this.getCashout({...filter, ...this.query})
    },
    searchDate(date) {
      if (date.length == 2) {
        const [startDate, endDate] = date
        const filter = {
          startDate,
          endDate,
          ...this.filter
        }

        delete filter.date

        this.getCashout({...filter, ...this.query})
      }
    },
    searchName(val) {
      const filter = {
        ...this.filter,
        searchTerm: val,
        startDate: this.filter.date[0] ?? '',
        endDate: this.filter.date[1] ?? '',
      }

      delete filter.date

      this.getCashout({...this.query, ...filter})
    },
    searchTrxStatus(val) {
      const filter = {
        ...this.filter,
        trxStatus: val,
        startDate: this.filter.date[0] ?? '',
        endDate: this.filter.date[1] ?? '',
      }

      delete filter.date

      this.getCashout({...this.query, ...filter})
    },
    exportExcel() {
      const data = this.cashouts.map((item) => {
        return {
          'Date & Time': item.createdAt,
          'No. Telepon': item.msisdn,
          'Reference ID': item.referenceId,
          'Bank Account': item.BankMaster.name,
          'No. Rekening': item.bankAccountNo,
          'Deducted Amount': this.rupiahFormat(item.deductedAmount),
          'Admin Fee': this.rupiahFormat(item.adminFee),
          'Transfered Amount': this.rupiahFormat(item.transferedAmount),
          'status': item.trxStatus
        }
      })

      excelParser().exportDataFromJSON(data, 'Cashouts', 'csv')
    }
  },
  mounted() {
    this.getCashout(this.query)
  },
  watch: {
    'query.page'(newValue) {
      if (this.filter.date.length == 2) {
        const [startDate, endDate] = this.filter.date
        const filter = {
          startDate,
          endDate,
          ...this.filter
        }

        delete filter.date

        this.getCashout({...filter, ...this.query})
      } else {
        this.getCashout({...this.query, ...this.filter})
      }
      // this.getCashout({...this.query, ...this.filter})
    },
    'query.limit'(newValue) {
      if (this.filter.date.length == 2) {
        const [startDate, endDate] = this.filter.date
        const filter = {
          startDate,
          endDate,
          ...this.filter
        }

        delete filter.date

        this.getCashout({...filter, ...this.query})
      } else {
        this.getCashout({...this.query, ...this.filter})
      }
      // this.getCashout({...this.query, ...this.filter})
    }
  }
};
</script>
